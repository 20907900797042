import { Hero } from '@ingeniorforeningen/figurine-cms';
import React from 'react';
import { LayoutProps } from '../types';

const FrontPageLayout = (props: LayoutProps) => {
  const { children } = props;

  return (
    <>
      <Hero data={props} />
      <section>{children}</section>
    </>
  );
};

export default FrontPageLayout;
