import appInsights from '@/appInsights';
import { deliveryClient } from './api';
import {
  IApiContentResponseModel,
  IApiMediaWithCropsResponseModel,
  PagedIApiContentResponseModel,
} from '@ingeniorforeningen/umbraco-client';
import { TBreadcrumbsProps } from '@ingeniorforeningen/figurine-core';

export const getBreadcrumbs = async ({
  path,
  locale,
}: {
  path: string;
  locale: string;
}): Promise<TBreadcrumbsProps['items']> => {
  const pathArray: string[] = path.split('/').filter((x) => x);
  let currentPath: string = '';

  const breadcrumbs: TBreadcrumbsProps['items'] = await Promise.all(
    pathArray.map(async (pathSegment: string) => {
      currentPath += '/' + pathSegment;
      const currentNode = (await deliveryClient
        .getContentByPath({
          path: currentPath,
          accept_Language: locale,
          api_Key: process.env.NEXT_PUBLIC_UMBRACO_API_KEY,
          start_Item: process.env.NEXT_PUBLIC_UMBRACO_SEGMENT,
        })
        .then((r) => r.toJSON() as IApiContentResponseModel)
        .catch((e) => {
          console.log(`failed retrieving page ${path}: `, JSON.stringify(e));
          return null;
        })) as IApiContentResponseModel | null;

      return {
        link: currentNode?.route?.path || '',
        label: currentNode?.name || '',
      };
    }),
  );

  return breadcrumbs;
};

export const getAllPages = async ({
  preview,
  locales,
  take = 999999,
}: {
  preview: boolean;
  locales: string[];
  take?: number;
}): Promise<(IApiContentResponseModel & { locale: string })[]> => {
  try {
    const allPages = (
      await Promise.all(
        locales?.map(async (locale) => {
          const pages = await deliveryClient
            .getContent({
              fetch: 'descendants:/',
              preview: preview,
              start_Item: process.env.NEXT_PUBLIC_UMBRACO_SEGMENT,
              api_Key: process.env.NEXT_PUBLIC_UMBRACO_API_KEY,
              accept_Language: locale,
              take: take,
              sort: ['level:asc'],
              filter: [
                'contentType:!idaforsikring_websiteSettings',
                'contentType:!idaForsikring_websiteData',
                'contentType:!idaForsikring_reusableContentList',
                'contentType:!idaForsikring_relatedContentList',
                'contentType:!idaForsikring_relatedContentSet',
                'contentType:!idaForsikring_reusableContent',
                'contentType:!idaForsikring_relatedContaxtBox',
                'contentType:!idaforsikring_search',
              ],
            })
            .then((r) => r.toJSON() as PagedIApiContentResponseModel);

          return pages.items
            .filter((page) => page.route)
            .map((page) => ({
              ...page,
              locale,
            }));
        }),
      )
    ).flat(1);

    return allPages as unknown as (IApiContentResponseModel & { locale: string })[];
  } catch (exception) {
    let error = new Error();
    if (typeof exception === 'string') {
      error = new Error(exception);
    }
    if (exception instanceof Error) {
      error = exception;
    }
    appInsights.trackException({
      properties: { message: 'Error fetching all pages' },
      exception: error,
    });
  }

  return [];
};

export const getSettings = async ({
  path,
  locale,
  preview,
}: {
  path?: string;
  locale: string;
  preview: boolean;
}) => {
  const apiKey = process.env.NEXT_PUBLIC_UMBRACO_API_KEY;

  const siteSettingsNode = (await deliveryClient
    .getContentById({
      id: process.env.NEXT_PUBLIC_UMBRACO_SETTINGS_NODE || '',
      accept_Language: locale,
      api_Key: apiKey,
      preview,
      start_Item: process.env.NEXT_PUBLIC_UMBRACO_SEGMENT,
    })
    .then((r) => r.toJSON() as IApiContentResponseModel)
    .catch((e) => {
      console.log(`failed retrieving settings for page  ${path}: `, JSON.stringify(e));
      return null;
    })) as IApiContentResponseModel | null;

  return siteSettingsNode;
};

export const getPageData = async ({
  path,
  locale,
  preview,
}: {
  path: string;
  locale: string;
  preview: boolean;
}) => {
  const settings = await getSettings({
    path,
    locale,
    preview,
  });

  const breadcrumbs = await getBreadcrumbs({ path, locale });

  const currentNode = (await deliveryClient
    .getContentByPath({
      path,
      accept_Language: locale,
      api_Key: process.env.NEXT_PUBLIC_UMBRACO_API_KEY,
      preview,
      start_Item: process.env.NEXT_PUBLIC_UMBRACO_SEGMENT,
      expand:
        'properties[relatedContentCards[properties[all]],image[properties[all]],components[properties[image[properties[all]],component[properties[all]]]]]',
    })
    .then((r) => r.toJSON() as IApiContentResponseModel)
    .catch((e) => {
      console.log(`failed retrieving page ${path}: `, JSON.stringify(e));
      return null;
    })) as IApiContentResponseModel | null;

  if (currentNode?.properties) {
  }

  if (currentNode?.properties && currentNode.properties.relatedContentCards) {
    currentNode.properties.relatedContentCards[0].properties.content.items =
      await fillWithFallbackRelated({
        path,
        locale,
        preview,
        currentNode,
      });

    currentNode.properties.relatedContentCards[0].properties.readMoreTitle =
      settings?.properties?.readMoreTitle;
  }

  // If there is no image on the current page, we need to fetch the frontpage node to get the image
  if (currentNode?.properties && !currentNode?.properties?.socialMediaImage?.[0]) {
    const frontpageNode = (await deliveryClient
      .getContentByPath({
        path: '/',
        api_Key: process.env.NEXT_PUBLIC_UMBRACO_API_KEY,
        preview: false,
        start_Item: process.env.NEXT_PUBLIC_UMBRACO_SEGMENT,
        expand: 'properties[image[properties[all]]]',
      })
      .then((r) => r.toJSON() as IApiContentResponseModel)
      .catch((e) => {
        console.log(`failed retrieving page "/": `, JSON.stringify(e));
        return null;
      })) as IApiContentResponseModel | null;

    currentNode.properties.socialMediaImage = frontpageNode?.properties?.image;
  }

  return {
    page: currentNode,
    settings: settings,
    breadcrumbs,
  };
};

export const calculateSoMeImageUrl = (props: IApiMediaWithCropsResponseModel[]) => {
  let result = process.env.NEXT_PUBLIC_UMBRACO_API_URL || '';
  let image = props?.[0];
  const params = [];

  if (image) {
    let url = image.url;
    let focal = image.focalPoint;

    result += url;

    if (image.crops && image.crops.length > 0) {
      const crop = image.crops.find((crop: any) => crop.alias === 'Landskab') || image.crops[0];
      params.push(`width=${crop.width}`);
      params.push(`height=${crop.height}`);
    }
    if (focal) {
      params.push(`rxy=${focal.left},${focal.top}`);
    }

    params.push('format=jpg');
  }

  return `${result}?${params.join('&')}`;
};

// If there are less than 3 related content cards, we need to fetch the fallback node
// This logic should be in the Umbraco API, but for the time being we do this here.
// @TODO: Move this logic to the Umbraco API
export const fillWithFallbackRelated = async ({
  path,
  locale,
  preview,
  currentNode,
}: {
  path: string;
  locale: string;
  preview: boolean;
  currentNode: IApiContentResponseModel;
}) => {
  if (currentNode.properties?.relatedContentCards.length >= 3) {
    return currentNode.properties?.relatedContentCards[0].properties.content.items;
  }

  const apiKey = process.env.NEXT_PUBLIC_UMBRACO_API_KEY;

  const relatedContentFallbackNode = (await deliveryClient
    .getContentById({
      id: process.env.NEXT_PUBLIC_UMBRACO_RELATED_FALLBACK || '',
      accept_Language: locale,
      api_Key: apiKey,
      preview,
      start_Item: process.env.NEXT_PUBLIC_UMBRACO_SEGMENT,
      expand: 'properties[content[properties[content[properties[all]]]]]',
    })
    .then((r) => r.toJSON() as IApiContentResponseModel)
    .catch((e) => {
      console.log(`failed retrieving settings for page  ${path}: `, JSON.stringify(e));
      return null;
    })) as IApiContentResponseModel | null;

  const relatedCards = currentNode?.properties?.relatedContentCards?.[0]?.properties.content.items;
  const fillAmount = 3 - relatedCards.length;

  for (let i = 0; i < fillAmount; i++) {
    const item = relatedContentFallbackNode?.properties?.content.items[i];

    // Because some of the related content cards are references to other cards, in which case we need to push the actual content and not the reference
    if (item.content.contentType.includes('Picker')) {
      relatedCards.push(item.content.properties.content[0].properties.content.items[0]);
    } else {
      relatedCards.push(item);
    }
  }

  return relatedCards;
};
